import axios from "axios";

const settings = {
  namespaced: true,
  state: {
    timeValues: false,
    delayValue: false,
    daysToRejectValues: false,
    daysToRejectValue: false,
  },
  mutations: {
    SET_TIME_VALUES(state, data) {
      state.timeValues = data;
    },
    SET_DELAY_VALUE(state, data) {
      state.delayValue = data;
    },
    SET_DAYS_TO_REJECT_VALUES(state, data) {
      state.daysToRejectValues = data;
    },
    SET_DAYS_TO_REJECT_VALUE(state, data) {
      state.daysToRejectValue = data;
    },
  },
  actions: {
    getSettings({ rootState, commit }) {
      axios.get(`${rootState.apiURL}/getSettings`).then((res) => {
        const settings = res.data.find((item) => item.id === "TPR Delay Timer");
        const daysToReject = res.data.find(
          (item) => item.id === "Days To Reject"
        );
        if (settings) {
          commit("SET_TIME_VALUES", settings.timeValues);
          commit("SET_DELAY_VALUE", settings.delayValue);
          commit("SET_DAYS_TO_REJECT_VALUES", daysToReject.daysToRejectValues);
          commit("SET_DAYS_TO_REJECT_VALUE", daysToReject.daysToRejectValue);
        }
      });
    },
    updateSettings({ rootState, commit }, payload) {
      axios.post(`${rootState.apiURL}/updateSettings`, payload).then((res) => {
        const data = res.data;
        if (data.delayValue) {
          commit("SET_DELAY_VALUE", data.delayValue);
        }
        if (data.daysToRejectValue) {
          commit("SET_DAYS_TO_REJECT_VALUE", data.daysToRejectValue);
        }
      });
    },
  },
  getters: {
    getTimeValues(state) {
      return state.timeValues;
    },
    getDelayValue(state) {
      return state.delayValue;
    },
    getDaysToRejectValues(state) {
      return state.daysToRejectValues;
    },
    getDaysToRejectValue(state) {
      return state.daysToRejectValue;
    },
  },
};
export default settings;
