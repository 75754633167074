var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"appbar"}},[_c('v-app-bar',{attrs:{"app":"","color":"amber","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-drawer')}}}),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mx-2",attrs:{"src":require('@/assets/logo.png'),"alt":"stryker","max-height":"36","contain":""}})],1)],1),_c('span',{staticClass:"font-weight-thin text-h4"},[_vm._v("|")]),_c('v-toolbar-title',[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"mx-2",attrs:{"src":require('@/assets/sp4.png'),"alt":"sp4","max-height":"14","max-width":"70","contain":""}})],1)],1),_c('v-spacer'),_c('v-autocomplete',{staticClass:"hidden-sm-and-down",attrs:{"flat":"","solo-inverted":"","prepend-inner-icon":"mdi-magnify","label":"Start typing to Search...","items":_vm.cases,"item-text":"patientName","item-value":"Id","hide-details":"","hide-no-data":"","hide-selected":"","return-object":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',{attrs:{"tile":"","color":"amber"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-clipboard-pulse")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(((item.UniqueIdentifier) + " - " + (item.patientName)))}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.caseStatus)}})],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-bell")])],1),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-apps")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","offset-X":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-card',{attrs:{"width":"480"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.currentAccount.lastName)+" , "+_vm._s(_vm.currentAccount.firstName))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.currentAccount.email))]),_c('v-divider'),_c('v-card-text',[_c('p',{staticClass:"subtitle-2"},[_vm._v("Role")]),_c('p',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.currentAccount.role))]),_c('p',{staticClass:"subtitle-2"},[_vm._v("Region")]),_c('p',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.currentAccount.region))]),(_vm.isSystemAdmin)?_c('v-text-field',{attrs:{"label":"Send global message","append-icon":"mdi-send"},on:{"click:append":_vm.sendMessage},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('span',{staticClass:"subtitle-2"},[_vm._v("Version: "+_vm._s(_vm.environment)+" - "+_vm._s(this.$store.state.appVersion))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"amber lighten-2","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }