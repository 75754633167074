import axios from 'axios';

const cases = {
  namespaced: true,
  state: {
    caseItem: {},
    cases: [],
    loading: true,
  },
  getters: {
    getCase(state) {
      return state.caseItem;
    },
    getCases(state) {
      return state.cases;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    SET_CASE(state, data) {
      state.caseItem = data;
    },
    SET_CASES(state, data) {
      state.cases = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    async getCase({ rootState, commit }, payload) {
      commit('SET_LOADING', true);
      let item = await axios.get(`${rootState.apiURL}/getCase?id=${payload}`).then((res) => {
        return res.data;
      });
      if (item) {
        commit('SET_CASE', item);
        commit('SET_LOADING', false);
      }
    },
    async getCases({ rootState, commit }) {
      commit('SET_LOADING', true);
      let cases = await axios.get(`${rootState.apiURL}/getCases`).then((res) => {
        return res.data;
      });
      if (cases) {
        commit('SET_CASES', cases);
        commit('SET_LOADING', false);
      }
    },
    async deleteCase({ rootState, dispatch }, payload) {
      let deleteCase = await axios.post(`${rootState.apiURL}/deleteCase`, payload).then((res) => {
        return res;
      });
      dispatch('getCases');
      console.log('Delete case response: ', deleteCase);
    },
    async updateCase({ rootState, commit }, payload) {
      commit('SET_LOADING', true);
      let updateCase = await axios.post(`${rootState.apiURL}/updateCase`, payload).then((res) => {
        return res;
      });
      commit('SET_LOADING', false);
      console.log('Update case response: ', updateCase);
    },
  },
};
export default cases;
