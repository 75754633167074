import axios from 'axios';

const tasks = {
  namespaced: true,
  state: {
    task: {},
    tasks: [],
    loading: false,
  },

  mutations: {
    SET_TASK(state, data) {
      state.task = data;
    },
    SET_TASKS(state, data) {
      state.tasks = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    getTasks({ rootState, commit }) {
      axios.get(`${rootState.apiURL}/getTasks`).then((res) => {
        let tasks = res.data;
        if (tasks) {
          commit('SET_TASKS', tasks);
          commit('SET_LOADING', false);
        }
      });
    },
  },
  getters: {
    getTask(state) {
      return state.task;
    },
    getTasks(state) {
      return state.tasks;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};
export default tasks;
