var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.pageName))])])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"4"}},[_c('CaseChart')],1),_c('v-col',{attrs:{"sm":"4"}},[_c('RegionChart')],1),_c('v-col',{attrs:{"sm":"4"}},[_c('SourceChart')],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('span',{staticClass:"h5"},[_vm._v("ACC Audit List")])]),_c('v-col',{attrs:{"sm":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.repsuite,"items-per-page":5},scopedSlots:_vm._u([{key:"item.primaryContact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.primaryContact.lastName)+", "+_vm._s(item.primaryContact.firstName)+" ")]}},{key:"item.inhouseSharingDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.inhouseSharingDateTime))+" ")]}},{key:"item.needByDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.needByDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" "+_vm._s(item.metadata.commentedRequestState.requestState)+" "),_c('v-icon',{attrs:{"color":"amber"}},[_vm._v(" mdi-alert ")])],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }