<template>
  <div class="region-chart">
    <canvas id="region-chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { mapGetters } from 'vuex';

export default {
  name: 'RegionChart',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cases', {
      cases: 'getCases',
      loading: 'getLoading',
    }),
    fll() {
      let fll = this.cases.filter((item) => {
        return item.segRegion === 'US - Fort Lauderdale, FL';
      });
      return fll.length;
    },
    kzoo() {
      let kzoo = this.cases.filter((item) => {
        return item.segRegion === 'US - Kalamazoo, MI';
      });
      return kzoo.length;
    },

    regionChartData() {
      return {
        type: 'bar',
        data: {
          labels: ['Fort Lauderdale, FL', 'Kalamazoo, MI'],
          datasets: [
            {
              data: [this.fll, this.kzoo],
              backgroundColor: ['rgb(255, 205, 86)'],
              hoverOffset: 4,
              barPercentage: 0.5,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'center',
            },
            title: {
              display: true,
              text: 'Seg Region',
            },
          },
        },
      };
    },
  },
  mounted() {
    const ctx = document.getElementById('region-chart');
    new Chart(ctx, this.regionChartData);
  },
};
</script>

<style></style>
