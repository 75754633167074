import axios from 'axios';

const surgeons = {
  namespaced: true,
  state: {
    surgeon: {},
    surgeons: [],
    loading: false,
  },

  mutations: {
    SET_SURGEON(state, data) {
      state.surgeon = data;
    },
    SET_SURGEONS(state, data) {
      state.surgeons = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    setSurgeon({ rootState, commit, dispatch }, payload) {
      axios.post(`${rootState.apiURL}/createSurgeon`, payload).then((res) => {
        const surgeon = res.data;
        if (surgeon) {
          commit('SET_SURGEON', surgeon);
          dispatch('setSurgeons');
        }
      });
    },
    setSurgeons({ rootState, commit }) {
      commit('SET_LOADING', true);
      axios.get(`${rootState.apiURL}/getSurgeons`).then((res) => {
        const surgeons = res.data;
        if (surgeons) {
          commit('SET_SURGEONS', surgeons);
          commit('SET_LOADING', false);
        }
      });
    },
    querySurgeons({ rootState, commit }, payload) {
      commit('SET_LOADING', true);
      axios.post(`${rootState.apiURL}/querySurgeons`, payload).then((res) => {
        const surgeons = res.data.items;
        if (surgeons) {
          commit('SET_SURGEONS', surgeons);
          commit('SET_LOADING', false);
        }
      });
    },
    deleteSurgeon({ rootState, dispatch }, payload) {
      axios.post(`${rootState.apiURL}/deleteSurgeon`, payload).then((res) => {
        dispatch('setSurgeons');
        console.log('Delete surgeon response: ', res);
      });
    },
  },
  getters: {
    getSurgeon(state) {
      return state.surgeon;
    },
    getSurgeons(state) {
      return state.surgeons;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};
export default surgeons;
