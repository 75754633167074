import Vue from 'vue';
import Vuex from 'vuex';
import accounts from './modules/accounts';
import holidays from './modules/holidays';
import users from './modules/users';
import cases from './modules/cases';
import surgeons from './modules/surgeons';
import tasks from './modules/tasks';
import messages from './modules/messages';
import snackbar from './modules/snackbar';
import settings from './modules/settings';
import { version } from '../../package';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiURL: null,
    loading: false,
    appVersion: version,
  },
  mutations: {
    SET_API_URL(state, data) {
      state.apiURL = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    setURL({ commit }, payload) {
      commit('SET_API_URL', payload);
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
  },
  getters: {
    getURL(state) {
      return state.apiURL;
    },
    getLoading(state) {
      return state.loading;
    },
  },
  modules: {
    accounts,
    users,
    holidays,
    cases,
    surgeons,
    tasks,
    messages,
    snackbar,
    settings,
  },
});
