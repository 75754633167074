import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import moment from 'moment';
import ws from './services/webSocket';
import axios from 'axios';
import Vidle from 'v-idle';

Vue.config.productionTip = false;

if (window.location.hostname == process.env.VUE_APP_PROD_HOSTNAME || process.env.VUE_APP_PROD_CNAME) {
  store.dispatch('setURL', 'https://func-gcas-prod.azurewebsites.net/api');
} else if (window.location.hostname == process.env.VUE_APP_STAGE_HOSTNAME || process.env.VUE_APP_STAGE_CNAME) {
  store.dispatch('setURL', 'https://func-gcas-stage.azurewebsites.net/api');
} else {
  store.dispatch('setURL', 'https://func-gcas-dev.azurewebsites.net/api');
}

Vue.filter('formatDate', (value) => {
  return moment(value, 'YYYY-MM-DD').format('L');
});

Vue.filter('formatDay', (value) => {
  return moment(value, 'YYYY-MM-DD').format('dddd');
});

Vue.prototype.$moment = moment;
Vue.prototype.$ws = ws;
Vue.prototype.$http = axios;

Vue.use(Vidle);

router.beforeEach(async (to, from, next) => {
  await store.dispatch('accounts/handleLoginRedirect');
  let account = await store.getters['accounts/getAccount'];
  ws.sendToAll({
    From: from.name,
    To: to.name,
    CreatedBy: account.username,
    CreatedAt: moment
      .utc()
      .subtract(4, 'hours')
      .format('MM-DD-YYYY HH:mm:ss'),
  });
  next();
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
