import axios from 'axios';

const users = {
  namespaced: true,
  state: {
    user: {},
    users: [],
    loading: true,
  },

  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
    SET_USERS(state, data) {
      state.users = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    setUser({ rootState, commit, dispatch }, payload) {
      axios.post(`${rootState.apiURL}/createUser`, payload).then((res) => {
        const user = res.data;
        if (user) {
          commit('SET_USER', user);
          dispatch('setUsers');
          console.log('Create user response: ', user);
        }
      });
    },
    setUsers({ rootState, commit }) {
      axios.get(`${rootState.apiURL}/getUsers`).then((res) => {
        const users = res.data;
        if (users) {
          commit('SET_USERS', users);
          commit('SET_LOADING', false);
        }
      });
    },
    deleteUser({ rootState, dispatch }, payload) {
      axios.post(`${rootState.apiURL}/deleteUser`, payload).then((res) => {
        dispatch('setUsers');
        console.log('Delete user response: ', res);
      });
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getUsers(state) {
      return state.users;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};
export default users;
