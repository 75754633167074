<template>
  <v-navigation-drawer v-model="drawer" app clipped color="grey lighten-4">
    <v-list dense>
      <v-list-item v-for="item in items" :key="item.title" :disabled="!isAuthorized(item.roles)" :to="item.path" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    drawer: true,
    items: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        path: '/',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor', 'Lead / Analyst', 'Senior Seg Rep'],
      },
      {
        title: 'Cases',
        icon: 'mdi-clipboard-pulse',
        path: '/cases',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor', 'Lead / Analyst', 'Senior Seg Rep'],
      },
      {
        title: 'Tasks',
        icon: 'mdi-clipboard-check',
        path: '/tasks',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor', 'Lead / Analyst', 'Senior Seg Rep'],
      },
      {
        title: 'Holidays',
        icon: 'mdi-calendar',
        path: '/holidays',
        roles: ['System Admin', 'Manager / Senior Manager'],
      },
      {
        title: 'Users',
        icon: 'mdi-account-multiple',
        path: '/users',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor'],
      },
      {
        title: 'MPS',
        icon: 'mdi-account-group',
        path: '/mps',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor', 'Lead / Analyst', 'Senior Seg Rep'],
      },
      {
        title: 'Surgeons',
        icon: 'mdi-doctor',
        path: '/surgeons',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor', 'Lead / Analyst'],
      },
      {
        title: 'Settings',
        icon: 'mdi-cog',
        path: '/Settings',
        roles: ['System Admin', 'Manager / Senior Manager', 'Senior Supervisor'],
      },
      // {
      //   title: 'Logs',
      //   icon: 'mdi-database',
      //   path: '/logs',
      //   roles: ['System Admin'],
      // },
      // {
      //   title: 'ACC',
      //   icon: 'mdi-robot',
      //   path: '/acc',
      //   roles: ['System Admin'],
      // },
    ],
  }),
  computed: {
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
  },
  methods: {
    isAuthorized(roles) {
      return roles.some((role) => role === this.currentAccount.role);
    },
  },
  watch: {},
  created() {},
};
</script>
