<template>
  <div id="appbar">
    <v-app-bar app color="amber" clipped-left>
      <v-app-bar-nav-icon @click.stop="$emit('toggle-drawer')"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/">
          <v-img class="mx-2" :src="require('@/assets/logo.png')" alt="stryker" max-height="36" contain></v-img>
        </router-link>
      </v-toolbar-title>
      <span class="font-weight-thin text-h4">|</span>
      <v-toolbar-title>
        <router-link to="/">
          <v-img
            class="mx-2"
            :src="require('@/assets/sp4.png')"
            alt="sp4"
            max-height="14"
            max-width="70"
            contain
          ></v-img>
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-autocomplete
        flat
        solo-inverted
        prepend-inner-icon="mdi-magnify"
        label="Start typing to Search..."
        class="hidden-sm-and-down"
        v-model="selected"
        :items="cases"
        item-text="patientName"
        item-value="Id"
        hide-details
        hide-no-data
        hide-selected
        return-object
      >
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item v-bind="attrs" v-on="on">
            <v-list-item-avatar tile color="amber">
              <v-icon dark>mdi-clipboard-pulse</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="`${item.UniqueIdentifier} - ${item.patientName}`"></v-list-item-title>
              <v-list-item-subtitle v-text="item.caseStatus"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <!-- Account Menu Start -->
      <v-menu bottom left offset-y offset-X :close-on-content-click="closeOnContentClick">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon x-large>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-card width="480">
          <v-card-title>{{ currentAccount.lastName }} , {{ currentAccount.firstName }}</v-card-title>
          <v-card-subtitle>{{ currentAccount.email }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <p class="subtitle-2">Role</p>
            <p class="body-2">{{ currentAccount.role }}</p>
            <!-- <p class="subtitle-2">THA 4.0</p> -->
            <!-- <p class="body-2">{{ currentAccount.isTHA4 ? 'YES' : 'NO' }}</p> -->
            <p class="subtitle-2">Region</p>
            <p class="body-2">{{ currentAccount.region }}</p>
            <v-text-field
              v-if="isSystemAdmin"
              v-model="message"
              label="Send global message"
              append-icon="mdi-send"
              @click:append="sendMessage"
            ></v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <span class="subtitle-2">Version: {{ environment }} - {{ this.$store.state.appVersion }}</span>
            <v-spacer></v-spacer>
            <v-btn color="amber lighten-2" text @click="logout">
              Logout
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- Account Menu End -->
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    name: 'AppBar',
    search: null,
    selected: null,
    closeOnContentClick: false,
    message: '',
  }),
  computed: {
    ...mapGetters('cases', { cases: 'getCases' }),
    ...mapGetters('accounts', { currentAccount: 'getCurrentAccount' }),
    isSystemAdmin() {
      return this.currentAccount.role === 'System Admin';
    },
    environment() {
      if (window.location.hostname == process.env.VUE_APP_PROD_HOSTNAME || process.env.VUE_APP_PROD_CNAME) {
        return 'PROD';
      } else if (window.location.hostname == process.env.VUE_APP_STAGE_HOSTNAME || process.env.VUE_APP_STAGE_CNAME) {
        return 'STAGE';
      } else {
        return 'DEV';
      }
    },
    isSeniorSegRep() {
      return this.$store.state.accounts.currentAccount.role === 'Senior Seg Rep';
    },
  },
  methods: {
    ...mapActions('accounts', { logout: 'logout' }),
    sendMessage() {
      if (this.message !== '') {
        this.$ws.sendToAll({ snackbar: true, message: this.message });
        this.message = '';
      }
    },
  },
  watch: {
    selected() {
      this.$router.push({
        name: 'Case View',
        params: { id: this.selected.id },
      });
      this.$nextTick(() => {});
    },
  },
  created() {},
};
</script>

<style></style>
