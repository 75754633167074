<template>
  <div>
    <v-overlay :value="!isAuthenticated">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-app v-if="isAuthenticated">
      <NavigationDrawer ref="drawer" />
      <AppBar @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />
      <v-main>
        <v-container>
          <router-view />
          <Snackbar />
        </v-container>
      </v-main>
      <v-idle @idle="onidle" @remind="onremind" :loop="true" :reminders="[600]" :duration="3600" />
      <v-dialog v-model="idleReminder" persistent width="640px">
        <v-card>
          <v-card-title class="amber"><span class="headline">Session timeout</span></v-card-title>
          <v-card-text class="mt-3">
            <p>
              You're being timed out due to inactivity. Please choose to stay signed in or to logoff. Otherwise, you
              will logged off automatically.
            </p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-btn text @click="onContinue">Continue</v-btn>
            <v-btn text color="warning" @click="logout">Logout</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppBar from './components/AppBar';
import NavigationDrawer from './components/NavigationDrawer';
import Snackbar from './components/Snackbar';
import client from './services/webSocket';

export default {
  name: 'App',
  components: { AppBar, NavigationDrawer, Snackbar },

  data: () => ({
    idleReminder: false,
    isConnected: false,
  }),
  computed: {
    ...mapGetters('accounts', {
      isAuthenticated: 'getIsAuthenticated',
      account: 'getAccount',
    }),
  },
  methods: {
    ...mapActions('accounts', { logout: 'logout' }),
    onidle() {
      this.idleReminder = false;
      this.logout();
    },
    onremind() {
      this.idleReminder = true;
    },
    onContinue() {
      this.idleReminder = false;
      this.$router.push({ path: '/' });
    },
    onLogOut() {
      this.idleReminder = false;
    },
  },
  async created() {
    await this.$store.dispatch('users/setUsers');
    await this.$store.dispatch('accounts/getAccount');
  },
  async mounted() {},
  watch: {
    account: {
      deep: true,
      async handler() {
        if (this.account.username.length >= 1 && !this.isConnected) {
          let token = await client.getClientAccessToken({ userId: this.account.username });
          const ws = new WebSocket(token.url);
          this.isConnected = true;
          ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            if (data.message === 'Case created!' || data.message === 'Case updated!') {
              if (!this.$store.state.cases.loading && !document.hidden) {
                // this.$store.dispatch('cases/getCases');
              }
            }
            if (data.snackbar) {
              this.$store.dispatch('snackbar/showSnack', { snackbar: true, message: data.task });
            }
            if (data.task) {
              this.$store.dispatch('snackbar/showSnack', { snackbar: true, message: data.task });
              this.$store.dispatch('setLoading', false);
            }
          };
        }
      },
    },
  },
};
</script>
