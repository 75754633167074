import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';

Vue.use(VueRouter);

function routerGuard(to, from, next) {
  var isAuthenticated = false;
  var courentAccount = JSON.parse(localStorage.getItem('currentAccount'));

  if (
    (to.name === 'Settings' || to.name === 'Logs') &&
    (courentAccount.role !== 'Lead / Analyst' || courentAccount.role !== 'Senior Seg Rep')
  ) {
    isAuthenticated = true;
  } else if (
    to.name === 'Holidays' &&
    (courentAccount.role === 'System Admin' || courentAccount.role === 'Manager / Senior Manager')
  ) {
    isAuthenticated = true;
  } else if (
    to.name === 'Users' &&
    (courentAccount.role !== 'Lead / Analyst' || courentAccount.role !== 'Senior Seg Rep')
  ) {
    isAuthenticated = true;
  } else if (to.name === 'Surgeons' && courentAccount.role !== 'Senior Seg Rep') {
    isAuthenticated = true;
  } else if (to.name === 'Automatic Case Creation' && courentAccount.role === 'System Admin') {
    isAuthenticated = true;
  } else {
    isAuthenticated = false;
  }

  if (isAuthenticated) {
    next();
  } else {
    next('/cases');
  }
}

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard' }],
    },
  },
  {
    path: '/cases',
    name: 'Cases',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'cases' }],
    },
    component: () => import(/* webpackChunkName: "cases" */ '../views/Cases.vue'),
  },
  {
    path: '/case/:id',
    name: 'Case',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'case' }],
    },
    component: () => import(/* webpackChunkName: "case" */ '../views/Case.vue'),
  },
  {
    path: '/case-view/:id',
    name: 'Case View',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'case-view' }],
    },
    component: () => import(/* webpackChunkName: "case" */ '../views/CaseView.vue'),
  },
  {
    path: '/holidays',
    name: 'Holidays',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'holidays' }],
    },
    component: () => import(/* webpackChunkName: "holidays" */ '../views/Holidays.vue'),
    beforeEnter: routerGuard,
  },
  {
    path: '/users',
    name: 'Users',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'users' }],
    },
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue'),
    beforeEnter: routerGuard,
  },
  {
    path: '/mps',
    name: 'MPS',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'mps' }],
    },
    component: () => import(/* webpackChunkName: "mps" */ '../views/MPS.vue'),
  },
  {
    path: '/surgeons',
    name: 'Surgeons',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'surgeons' }],
    },
    component: () => import(/* webpackChunkName: "surgeons" */ '../views/Surgeons.vue'),
    beforeEnter: routerGuard,
  },
  {
    path: '/tasks',
    name: 'Tasks',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'tasks' }],
    },
    component: () => import(/* webpackChunkName: "tasks" */ '../views/Tasks.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'settings' }],
    },
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    beforeEnter: routerGuard,
  },
  {
    path: '/logs',
    name: 'Logs',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'logs' }],
    },
    component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue'),
    beforeEnter: routerGuard,
  },
  {
    path: '/acc',
    name: 'Automatic Case Creation',
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: 'dashboard', href: 'Dashboard' }, { name: 'Automatic Case Creation' }],
    },
    component: () => import(/* webpackChunkName: "AutomaticCaseCreation" */ '../views/AutomaticCaseCreation.vue'),
    beforeEnter: routerGuard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
