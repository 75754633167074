import * as msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: 'a3155ad0-c8c2-4b0b-b7bc-459505959620',
    authority: 'https://login.microsoftonline.com/4e9dbbfb-394a-4583-8810-53f81f819e3b/',
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;
