<template>
  <div class="snackbar">
    <v-snackbar v-model="snackbar" timeout="10000">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amber" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({}),
  computed: {
    ...mapGetters('snackbar', { message: 'getMessage' }),
    snackbar: {
      get() {
        return this.$store.state.snackbar.snackbar;
      },
      set(value) {
        this.$store.commit('snackbar/SET_SNACKBAR', value);
      },
    },
  },
};
</script>

<style></style>
