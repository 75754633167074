<template>
  <div class="case-chart">
    <canvas id="case-chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { mapGetters } from 'vuex';

export default {
  name: 'CaseChart',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cases', {
      cases: 'getCases',
      loading: 'getLoading',
    }),
    tka() {
      let tka = this.cases.filter((item) => {
        return item.caseType === 'TKA';
      });
      return tka.length;
    },
    tha() {
      let tha = this.cases.filter((item) => {
        return item.caseType === 'THA';
      });
      return tha.length;
    },

    caseChartData() {
      return {
        type: 'bar',
        data: {
          labels: ['TKA', 'THA'],
          datasets: [
            {
              data: [this.tka, this.tha],
              backgroundColor: ['rgb(255, 205, 86)'],
              hoverOffset: 4,
              barPercentage: 0.5,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'center',
            },
            title: {
              display: true,
              text: 'Case Type',
            },
          },
        },
      };
    },
  },
  mounted() {
    const ctx = document.getElementById('case-chart');
    new Chart(ctx, this.caseChartData);
  },
};
</script>

<style></style>
