import { WebPubSubServiceClient, AzureKeyCredential } from '@azure/web-pubsub';

const cred = new AzureKeyCredential('AvLeeoHcFK1mhQW5Vp388vqFzN9NwWTiYgEV/xcuogQ=');
const endpoint = 'https://gcas-dev.webpubsub.azure.com';

const client = new WebPubSubServiceClient(endpoint, cred, 'Hub');

export default client;

//   let token = await client.getAuthenticationToken();
//   const ws = new WebSocket(token.url);

//   ws.onopen = () => ws.se('WebSocked Conected!');
//   ws.onmessage = (event) => console.log(event);
