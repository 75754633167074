import { WebPubSubServiceClient, AzureKeyCredential } from '@azure/web-pubsub';

const cred = new AzureKeyCredential('AvLeeoHcFK1mhQW5Vp388vqFzN9NwWTiYgEV/xcuogQ=');
const endpoint = 'https://gcas-dev.webpubsub.azure.com';

const messages = {
  namespaced: true,
  state: {
    socket: [],
    message: {},
    messages: [],
  },

  mutations: {
    SET_SOCKET(state, data) {
      state.socket = data;
    },
    SET_MESSAGE(state, data) {
      state.message = data;
    },
    SET_MESSAGES(state, data) {
      state.messages = data;
    },
  },
  actions: {
    async setSocket({ commit }) {
      const client = new WebPubSubServiceClient(endpoint, cred, 'Hub');
      let token = await client.getAuthenticationToken();

      const ws = new WebSocket(token.url);
      commit('SET_SOCKET', ws);
      ws.onopen = () => ws.send('WebSocked Conected!');
      ws.onmessage = (event) => console.log(event);
    },
  },
  getters: {
    getMessage(state) {
      return state.message;
    },
    getMessages(state) {
      return state.messages;
    },
  },
};
export default messages;
