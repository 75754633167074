<template>
  <div class="source-chart">
    <canvas id="source-chart"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import { mapGetters } from 'vuex';

export default {
  name: 'SourceChart',
  data() {
    return {};
  },
  computed: {
    ...mapGetters('cases', {
      cases: 'getCases',
      loading: 'getLoading',
    }),
    repsuite() {
      let repsuite = this.cases.filter((item) => {
        return item.primaryExamId.includes('C-') || item.secondaryExamId.includes('C-');
      });
      return repsuite.length;
    },
    makosuite() {
      let repsuite = this.cases.filter((item) => {
        return item.primaryExamId.includes('R-') || item.secondaryExamId.includes('R-');
      });
      return repsuite.length;
    },

    sourceChartData() {
      return {
        type: 'bar',
        labels: 'Intake Source',
        data: {
          labels: ['RepSuite', 'MakoSuite'],
          datasets: [
            {
              data: [this.repsuite, this.makosuite],
              backgroundColor: ['rgb(255, 205, 86)'],
              hoverOffset: 4,
              barPercentage: 0.5,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'center',
            },
            title: {
              display: true,
              text: 'Intake Source',
            },
          },
        },
      };
    },
  },
  mounted() {
    const ctx = document.getElementById('source-chart');
    new Chart(ctx, this.sourceChartData);
  },
};
</script>

<style></style>
