import msalInstance from '../../services/authService';

const accounts = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    account: {},
    accessToken: '',
    currentAccount: {},
  },

  mutations: {
    SET_IS_AUTHENTICATED(state, data) {
      state.isAuthenticated = data;
    },
    SET_ACCOUNT(state, data) {
      state.account = data;
    },
    SET_CURRENT_ACCOUNT(state, data) {
      state.currentAccount = data;
    },
    SET_ACCESS_TOKEN(state, data) {
      state.accessToken = data;
    },
  },
  actions: {
    async handleLoginRedirect({ state, commit, rootGetters }) {
      let isAuthenticated;
      await rootGetters['users/getUsers'];
      msalInstance
        .handleRedirectPromise()
        .then((res) => {
          if (res !== null) {
            if (res.idTokenClaims.groups) {
              isAuthenticated = res.idTokenClaims.groups.some(
                (group) => group === 'GCAS-Admin' || group === 'SG-MKFS00- In-House CT Segmentation'
              );
            } else {
              isAuthenticated = res.idTokenClaims.roles.some((role) => role === 'User');
            }
            if (isAuthenticated) {
              commit('SET_IS_AUTHENTICATED', isAuthenticated);
              commit('SET_ACCESS_TOKEN', res.accessToken);
              commit('SET_ACCOUNT', res.account);
            } else {
              commit('SET_IS_AUTHENTICATED', isAuthenticated);
            }
          } else {
            try {
              msalInstance.loginRedirect({});
            } catch (err) {
              console.log('Login Error', err);
            }
          }
        })
        .finally(() => {
          commit(
            'SET_CURRENT_ACCOUNT',
            rootGetters['users/getUsers'].find(
              (user) => user.email === rootGetters['accounts/getAccount'].username && user.userType === 'User'
            )
          );
          localStorage.setItem('currentAccount', JSON.stringify(state.currentAccount));
        });
    },
    login() {
      msalInstance.loginRedirect({});
    },
    async logout({ state }) {
      const currentAccount = msalInstance.getAccountByHomeId(state.account.homeAccountId);
      await msalInstance.logoutRedirect({ account: currentAccount });
      console.log(currentAccount);
    },
    getAccount({ commit }) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts) {
        commit('SET_ACCOUNT', accounts[0]);
        return;
      } else {
        commit('SET_ACCOUNT', null);
      }
    },
    getToken({ state, commit }) {
      const currentAccount = msalInstance.getAccountByHomeId(state.account.homeAccountId);
      var silentRequest = {
        scopes: ['User.Read'],
        account: currentAccount,
        forceRefresh: false,
      };

      var request = {
        scopes: ['User.Read'],
        loginHint: currentAccount.username,
      };
      msalInstance.acquireTokenSilent(silentRequest).then(
        (res) => {
          commit('SET_ACCESS_TOKEN', res.accessToken);
          console.log('token exist', res);
        },
        (error) => {
          console.log(error);
          return msalInstance.acquireTokenRedirect(request).then(
            (res) => {
              commit('SET_ACCESS_TOKEN', res.accessToken);
            },
            (err) => {
              console.log(err);
            }
          );
        }
      );
    },
  },
  getters: {
    getIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    getAccount(state) {
      return state.account;
    },
    getAccessToken(state) {
      return state.accessToken;
    },
    getCurrentAccount(state) {
      return state.currentAccount;
    },
  },
};

export default accounts;
