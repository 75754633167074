import axios from 'axios';

const holidays = {
  namespaced: true,
  state: {
    loading: true,
    holidays: [],
  },
  mutations: {
    SET_HOLIDAYS(state, data) {
      state.holidays = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
  actions: {
    getHolidays({ rootState, commit }) {
      axios.get(`${rootState.apiURL}/getHolidays`).then((res) => {
        const holidays = res.data;
        if (holidays) {
          commit('SET_HOLIDAYS', holidays);
          commit('SET_LOADING', false);
        }
      });
    },
  },
  getters: {
    getHolidays(state) {
      return state.holidays;
    },
    getLoading(state) {
      return state.loading;
    },
  },
};
export default holidays;
