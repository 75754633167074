const snackbar = {
  namespaced: true,
  state: {
    snackbar: false,
    message: 'Default message!',
  },
  mutations: {
    SET_SNACKBAR(state, data) {
      state.snackbar = data;
    },
    SET_MESSAGE(state, data) {
      state.message = data;
    },
  },
  actions: {
    showSnack({ commit }, payload) {
      commit('SET_MESSAGE', payload.message);
      commit('SET_SNACKBAR', payload.snackbar);
    },
  },
  getters: {
    getSnackbar(state) {
      return state.snackbar;
    },
    getMessage(state) {
      return state.message;
    },
  },
};
export default snackbar;
